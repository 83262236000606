<template>
  <div style="position: relative">
    <div style="width: 100%; display: flex; justify-content: center">
      <svg viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"
           class="ytm-materials-plus-button" tabindex="0"
           @focus="showDropdown = true" @blur="showDropdown = false"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M36 4.5C36 2.01472 33.9853 0 31.5 0C29.0147 0 27 2.01472 27 4.5V26H4.5C2.01472 26 0 28.0147 0 30.5C0 32.9853 2.01472 35 4.5 35H27V57.5C27 59.9853 29.0147 62 31.5 62C33.9853 62 36 59.9853 36 57.5V35H57.5C59.9853 35 62 32.9853 62 30.5C62 28.0147 59.9853 26 57.5 26H36V4.5Z" fill="#07A8F0"/>
      </svg>
    </div>
    <div v-if="showDropdown" class="ytm-materials-dropdown" tabindex="-1">
      <div v-for="(option, idx) in options" :key="option.name">
        <div class="ytm-materials-dropdown-option" @mousedown="option.action">
          <div style="flex-grow: 1; margin-right: 1rem; display: table">
            <p class="ytm-default-text ytm-materials-dropdown-text" style="display: table-cell; vertical-align: middle">
              {{option.name}}
            </p>
          </div>
          <div style="display: table">
            <div style="display: table-cell; vertical-align: middle">
              <svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 0.9rem">
                <path d="M1 1L8 8L1 15" stroke="#00171F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
        </div>
        <hr v-if="idx + 1 !== options.length" style="margin: 0">
      </div>
    </div>
  </div>
</template>

<script>
import "@/styles/style.css";

export default {
  name: 'DropdownWithTypes',
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
};
</script>

<style scoped>
.ytm-materials-plus-button {
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
  outline: none;
  transition: 0.2s ease-in-out;
}

.ytm-materials-plus-button:hover {
  transform: scale(1.05);
}

.ytm-materials-dropdown {
  z-index: 99;
  position: absolute;
  margin-top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 0.5rem;
  border-radius: 1rem;
  background-color: #FFFFFF;
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.25);
}

.ytm-materials-dropdown-option {
  display: flex;
  cursor: pointer;
  padding: 0 1rem;
  margin: 0.5rem 0;
  transition: ease-in-out 0.2s;
}

.ytm-materials-dropdown-option:hover {
  transform: scale(1.03);
}

.ytm-materials-dropdown-text {
  font-weight: 400;
  font-size: 1rem;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
}
</style>