<template>
    <div class="ytm-material-wrapper">
        <p
                v-if="material.material.description"
                style="margin-bottom: 0.5rem"
        >
            {{material.material.description}}
        </p>
        <Gallery :images="material.material.images"/>
    </div>
</template>

<script>
import Gallery from "@/components/common/Gallery/Gallery.vue";

export default {
    name: 'Images',
    components: {Gallery},
    props: {
        material: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
.ytm-material-wrapper {
    margin: 0 0 1rem;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    border: 1px solid #e9ecef;
    transition: 0.1s all ease-in-out;
}

.ytm-material-wrapper:hover {
    border-color: #68cef8;
}
</style>