<template>
  <div style="position: relative">
    <i
        contenteditable="false"
        data-drag-handle
        class="fa-solid fa-ellipsis-vertical ytm-material-menu"
        style="cursor: pointer"
        tabindex="0"
        @click="toggleSettings"
        @blur="closeSettings"
    />
    <div v-if="settingsOpened" style="position: relative; height: 100%; font-weight: 400">
      <div style="position: absolute; left: 0; top: 0; z-index: 998; opacity: 1">
        <div class="ytm-tiptap-add-menu">
          <div class="ytm-default-text ytm-tiptap-add-menu-head">
            Настройки
          </div>
          <div
              v-for="option in settings_options"
              :key="option.id"
              class="ytm-tiptap-add-menu-item"
              @mousedown="option.action"
          >
            <div style="display: table-cell; vertical-align: middle; width: 2.3rem">
              <svg class="remix" style="margin: 6px 0 0 0; height: 1.6rem; width: 1.5rem">
                <use :xlink:href="`${remixiconUrl}#ri-${option.icon}`" />
              </svg>
            </div>
            <div style="display: table-cell; vertical-align: middle">
              <p class="ytm-default-text" style="font-size: 0.8rem; font-weight: 400">
                {{option.title}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/styles/style.css";
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg';

export default {
  name: 'SideMenu',
  data() {
    return {
      remixiconUrl,
      settingsOpened: false,
      settings_options: [
        {
          id: 1,
          title: 'Удалить',
          action: () => { this.$emit('delMaterial'); },
          icon: 'delete-bin-line',
        },
      ],
    };
  },
  methods: {
    toggleSettings() {
      this.settingsOpened = true;
      this.$emit('openMenu');
    },
    closeSettings() {
      this.settingsOpened = false;
      this.$emit('closeMenu');
    },
  },
};
</script>

<style scoped>
.ytm-material-menu {
  padding-left: 4px;
  padding-right: 6px;
  margin-right: 6px;
  cursor: pointer;
  color: gray;
}
.ytm-tiptap-add-menu {
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 12px 0 #c0c0c0;
  width: 270px;
  max-height: 170px;
  padding: 6px 0;
  overflow: auto;
}
.ytm-tiptap-add-menu-head {
  padding: 0 14px;
  margin-top: 6px;
  margin-bottom: 8px;
  font-size: 11px;
  font-weight: 600;
  line-height: 120%;
  user-select: none;
  text-transform: uppercase;
  color: dimgray;
}
.ytm-tiptap-add-menu-item {
  width: calc(100% - 24px);
  margin: 2px 12px;
  padding: 0 7px;
  display: table;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}
.ytm-tiptap-add-menu-item:hover {
  background-color: #eaeaea;
}
</style>