<template>
    <div>
        <div class="ytm-circle-button" v-show="showCircleButton && !draftsShown" @click="showDrafts" style="display: flex">
            <svg viewBox="0 0 34 29" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-drafts-icon">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.895431 0.895431 0 2 0H21C22.1046 0 23 0.895431 23 2V6.40429L12.177 28H2C0.89543 28 0 27.1046 0 26V2ZM28.3925 2.63282C28.8488 1.73104 29.9498 1.3699 30.8516 1.8262L32.4844 2.65241C33.3862 3.10871 33.7473 4.20965 33.291 5.11143L24.2028 23.0724C23.7465 23.9742 22.6455 24.3353 21.7437 23.879L20.1109 23.0528C19.2091 22.5965 18.848 21.4956 19.3043 20.5938L28.3925 2.63282ZM17.9484 27.5986C17.9872 28.3019 18.7728 28.6994 19.3624 28.3142L22.0158 26.5805C22.6054 26.1952 22.5569 25.3161 21.9284 24.9981L19.1003 23.5671C18.4718 23.249 17.7348 23.7306 17.7736 24.4339L17.9484 27.5986Z" fill="white"/>
            </svg>
            <div class="ytm-drafts-button-text-wrapper">
                <div class="ytm-default-text ytm-drafts-button-text">
                    К черновикам
                </div>
            </div>
        </div>
        <ExpandCollapseTransition :heightPx="draftsHeight">
            <div v-show="draftsShown" class="ytm-drafts-wrapper" ref="draftsWrapper">
                <div class="ytm-drafts-resizer-wrapper">
                    <div
                        ref="resizer"
                        class="ytm-drafts-resizer"
                        @touchstart="onTouchStart"
                        @touchmove="onTouchMove"
                        @touchend="onTouchEnd"
                        @mousedown.prevent="startResizingDrafts"
                        @mousemove.prevent="onResizingDrafts"
                        @mouseup="stopResizingDrafts"
                        @mouseleave="stopResizingDrafts"
                    >
                        <div class="ytm-drafts-resizer-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="height: 1.25rem; width: 1.25rem">
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path d="M5 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm14 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-7 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-drafts-x" @click="hideDrafts">
                    <circle cx="16.5" cy="16.5" r="16.5" fill="#DDDDDD"/>
                    <path d="M11 11.0991L22.6636 21.6026" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    <path d="M22.0811 10.5175L11.5811 22.1842" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>
                <div class="ytm-container" style="margin-bottom: 15rem">
                    <div style="width: 100%; text-align: center; margin-top: 1.25rem; margin-bottom: 2rem">
                        <p class="ytm-default-text" style="font-size: 1.5rem; font-weight: 600">
                            Черновики
                        </p>
                    </div>
                    <div v-if="drafts.length === 0" style="width: 100%; padding: 0 20%; text-align: center">
                        <p class="ytm-default-text" style="font-size: 1.25rem; font-weight: 400; margin-top: 2rem; margin-bottom: 1.5rem">
                            Пока нет черновиков
                        </p>
                    </div>
                    <div v-for="draft in drafts" :key="draft.id">
                        <div class="ytm-materials-wrapper">
                            <div :class="{'ytm-material-menu': openedMenu !== draft.id}">
                                <SideMenu
                                    @openMenu="openedMenu = draft.id"
                                    @closeMenu="closeMenu(draft.id)"
                                    @delMaterial="deleteDraft(draft)"
                                />
                            </div>
                            <div style="flex: 1; width: 100%; max-width: 100%">
                                <ImagesDraft
                                    v-if="draft.type === 'images'"
                                    :draft="draft"
                                    :ableToPublish="ableToPublish"
                                    @publish="publishMaterial(draft)"
                                />
                                <TestDraft
                                    v-if="draft.type === 'test'"
                                    :draft="draft.content"
                                    :ableToPublish="ableToPublish"
                                    :ref="'draft_' + draft.id"
                                    @publish="publishMaterial(draft)"
                                    @save_draft="saveDraft(draft)"
                                />
                            </div>
                        </div>
                    </div>
                    <DropdownWithTypes :options="optionsForDropdown" style="margin-bottom: 2rem"/>
                </div>
            </div>
        </ExpandCollapseTransition>
    </div>
</template>

<script>
import "@/styles/style.css";
import "@/components/CoursePageV2/skeletons.css";
import {inject} from "vue";
import SideMenu from "@/components/MaterialsV2/SideMenu";
import ImagesDraft from "@/components/MaterialsV2/Images/ImagesDraft";
import TestDraft from "@/components/MaterialsV2/Test/TestDraft";
import DropdownWithTypes from "@/components/MaterialsV2/DropdownWithTypes";
import publishMaterial from "@/components/MaterialsV2/publish_material";
import ExpandCollapseTransition from "@/components/redesign/Transitions/ExpandCollapseTransition.vue";
import axios from "axios";
import {SERVICE_MATERIALS_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export default {
    name: 'Drafts',
    components: {ExpandCollapseTransition, DropdownWithTypes, SideMenu, ImagesDraft, TestDraft},
    props: {
        roomId: {
            type: String,
            required: true,
        },
        typesConfig: {
            type: Object,
            required: true,
        },
        ableToPublish: {
            type: Boolean,
            default: false,
        },
        showCircleButton: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const areMaterialsActive = inject('areMaterialsActive');
        const drafts = inject('drafts');
        return {areMaterialsActive, drafts};
    },
    computed: {
        nextDraftId() {
            return this.drafts.reduce((max, draft) => Math.max(max, draft.id), 0) + 1;
        },
        optionsForDropdown() {
            const allTypes = [
                { slug: 'images', action: this.newImagesMaterialDraft },
                { slug: 'test', action: this.newTestDraft },
            ];
            const filteredTypes = [];
            allTypes.forEach(type => {
                if (this.typesConfig.hasOwnProperty(type.slug)) {
                    type.name = this.typesConfig[type.slug].name;
                    filteredTypes.push(type);
                }
            });
            return filteredTypes;
        },
    },
    data() {
        return {
            draftsHeight: 0,
            draftsShown: false,
            openedMenu: null,
            resizing: false,
            prevTouchY: null,
        };
    },
    methods: {
        showDrafts() { this.draftsShown = true },
        hideDrafts() { this.draftsShown = false },
        closeMenu(materialId) {
            if (this.openedMenu === materialId) {
                this.openedMenu = null;
            }
        },
        onTouchStart(evt) {
            if (evt.cancelable) {
                evt.preventDefault();
            }
            this.resizing = true;
            this.prevTouchY = evt.targetTouches[0].pageY;
        },
        onTouchMove(evt) {
            if (this.resizing && this.prevTouchY) {
                const curTouchY = evt.targetTouches[0].pageY;
                if (curTouchY !== this.prevTouchY) {
                    this.draftsHeight = Math.max(this.$refs.draftsWrapper.clientHeight - curTouchY + this.prevTouchY, 80);
                    this.$refs.draftsWrapper.style.height = `${this.draftsHeight}px`;
                    this.prevTouchY = curTouchY;
                }
            }
        },
        onTouchEnd() {
            this.resizing = false;
        },
        startResizingDrafts() {
            this.$refs.resizer.style.height = '10rem';
            this.$refs.resizer.style.bottom = '-4.375rem';
            this.$refs.resizer.style.cursor = 'grabbing';
            this.resizing = true;
        },
        onResizingDrafts(evt) {
            if (this.resizing && evt.movementY !== 0) {
                this.draftsHeight = Math.max(this.$refs.draftsWrapper.clientHeight - evt.movementY, 120);
                this.$refs.draftsWrapper.style.height = `${this.draftsHeight}px`;
            }
        },
        stopResizingDrafts() {
            if (this.resizing) {
                this.$refs.resizer.style.height = '1.25rem';
                this.$refs.resizer.style.bottom = '0';
                this.$refs.resizer.style.cursor = 'grab';
                this.resizing = false;
            }
        },
        newImagesMaterialDraft() {
            this.drafts.push({
                id: this.nextDraftId,
                materialId: null,
                type: 'images',
                description: '',
                files: [],
            });
        },
        newTestDraft() {
            this.drafts.push({
                id: this.nextDraftId,
                materialId: null,
                type: 'test',
                content: null,
            });
        },
        publishMaterial(draft) {
            if (draft.type === 'test') {
                const editor = this.$refs['draft_' + draft.id][0].$refs.editor;
                if (editor.validate()) {
                    draft.content = editor.getTestData();
                    this.saveDraft(draft, true);
                }
                return;
            }
            publishMaterial(this.roomId, draft).then(() => this.deleteDraft(draft));
        },
        saveDraft(draft, publishing = false) {
            this.$store.dispatch('auth/autoLogin').then(() => {
                const payload = {
                    publishing: publishing,
                };
                if (draft.materialId !== null) {
                    payload.id = draft.materialId;
                }
                if (draft.type === 'test') {
                    const editor = this.$refs['draft_' + draft.id][0].$refs.editor;
                    payload.test = editor.getTestData();
                }
                axios.post(
                    SERVICE_MATERIALS_URI + '/drafts/save',
                    payload,
                    {
                        headers: authHeader(),
                        params: {
                            materials_id: this.roomId,
                        },
                    },
                ).then(resp => {
                    if (!publishing) {
                        this.drafts.forEach((value, idx) => {
                            if (value.id === draft.id) {
                                this.drafts[idx].materialId = resp.data.out.id;
                                this.drafts[idx].content = resp.data.out.material;
                            }
                        });
                    } else {
                        this.drafts = this.drafts.filter(value => value.id !== draft.id);
                    }
                });
            });
        },
        deleteDraft(draft) {
            if (draft.materialId) {
                this.$store.dispatch('auth/autoLogin').then(() => {
                    axios.delete(
                        SERVICE_MATERIALS_URI + '/materials',
                        {
                            headers: authHeader(),
                            params: {
                                materials_id: this.roomId,
                                material_id: draft.materialId,
                            },
                        },
                    );
                });
            }
            this.drafts = this.drafts.filter(value => { return value.id !== draft.id });
        },
    },
    mounted() {
        this.draftsHeight = Math.round(window.innerHeight * 0.47);
        this.$refs.draftsWrapper.style.height = `${this.draftsHeight}px`;
    },
};
</script>

<style scoped>
.ytm-circle-button {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    display: block;
    height: 3rem;
    min-width: 3rem;
    max-width: 3rem;
    overflow: hidden;
    background-color: #000000;
    border: none;
    border-radius: 1.5rem;
    cursor: pointer;
    transition: max-width 0.5s ease;
}

.ytm-circle-button:hover {
    max-width: 20rem;
    transition: all 0.5s ease;
}

.ytm-drafts-icon {
    position: relative;
    top: 0.75rem;
    left: 0.75rem;
    height: 1.6rem;
    min-height: 1.6rem;
    width: 1.6rem;
    min-width: 1.6rem;
}

.ytm-drafts-button-text-wrapper {
    display: table;
    margin-left: 1.3rem;
    margin-right: 0.8rem;
}

.ytm-drafts-button-text {
    color: #FFFFFF;
    font-weight: 600;
    display: table-cell;
    vertical-align: middle;
    user-select: none;
    white-space: nowrap;
}

.ytm-drafts-wrapper {
    position: fixed;
    max-height: 100vh;
    bottom: 0;
    left: 0;
    right: 0;
    background: #FFFFFF;
    box-shadow: 10px 4px 100px 20px rgba(0, 0, 0, 0.25);
    overflow: scroll;
    z-index: 999;
}

.ytm-drafts-x {
    position: fixed;
    margin-top: 0.9rem;
    margin-left: calc(100% - 2.4rem);
    width: 1.5rem;
    height: 1.5rem;
    z-index: 999;
    cursor: pointer;
}

.ytm-materials-wrapper {
    display: flex;
}

.ytm-material-menu {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.ytm-materials-wrapper:hover .ytm-material-menu {
    opacity: 1;
}

.ytm-drafts-resizer-wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    z-index: 100;
    width: 100%;
}
.ytm-drafts-resizer {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    cursor: grab;
}
.ytm-drafts-resizer-icon {
    height: 1.25rem;
    background-color: #FFFFFF;
    padding: 0 1rem;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 25%);
}
</style>