<template>
  <div>
    <div class="skeleton ytm-material-skeleton"/>
    <div class="skeleton ytm-material-skeleton"/>
    <div class="skeleton ytm-material-skeleton"/>
  </div>
</template>

<script>
import "@/components/CoursePageV2/skeletons.css";

export default {
  name: 'MaterialsSkeletons',
};
</script>

<style scoped>
.ytm-material-skeleton {
  width: 100%;
  height: 5rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}
</style>