<template>
    <div>
        <div v-if="materialsConfig.ableToUseDrafts && materialsConfig.showDraftsButtonAtHead" style="width: 100%; display: flex; justify-content: center">
            <div class="ytm-transparent-button" style="padding: 0.6rem 1.8rem" @click="showDrafts">
                <div style="display: flex">
                    <svg viewBox="0 0 34 29" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 1.5rem; width: 1.5rem; margin-right: 0.5rem">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.895431 0.895431 0 2 0H21C22.1046 0 23 0.895431 23 2V6.40429L12.177 28H2C0.89543 28 0 27.1046 0 26V2ZM28.3925 2.63282C28.8488 1.73104 29.9498 1.3699 30.8516 1.8262L32.4844 2.65241C33.3862 3.10871 33.7473 4.20965 33.291 5.11143L24.2028 23.0724C23.7465 23.9742 22.6455 24.3353 21.7437 23.879L20.1109 23.0528C19.2091 22.5965 18.848 21.4956 19.3043 20.5938L28.3925 2.63282ZM17.9484 27.5986C17.9872 28.3019 18.7728 28.6994 19.3624 28.3142L22.0158 26.5805C22.6054 26.1952 22.5569 25.3161 21.9284 24.9981L19.1003 23.5671C18.4718 23.249 17.7348 23.7306 17.7736 24.4339L17.9484 27.5986Z" fill="black"/>
                    </svg>
                    <div style="display: table">
                        <div class="ytm-default-text" style="display: table-cell; vertical-align: middle; font-weight: 600">
                            К черновикам
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!loadingMaterials && materialIds.length === 0" style="width: 100%; padding: 0 20%; text-align: center">
            <p
                class="ytm-default-text"
                v-html="materialsConfig.messageIfNoMaterials"
                style="font-size: 1.25rem; line-height: 132%; font-weight: 400; margin-top: 2rem; margin-bottom: 1.5rem"
            />
        </div>
        <div
            v-for="(materialId, idx) in materialIds" :key="materialId"
            :class="{'ytm-materials-wrapper': true, 'ytm-materials-wrapper-dragging': dragging}"
            :ref="'wrapper_' + materialId"
            draggable="false"
            @dragstart="onStartDragging($event, materialId)"
            @dragenter.self="onDragEnter($event)"
            @dragover.prevent
            @dragleave.self="onDragLeave($event)"
            @dragend="onStopDragging(materialId)"
            @drop="onDrop($event, idx)"
        >
            <div v-show="materialsConfig.ableToPostNewMaterials" :class="{'ytm-material-menu': openedMenu !== materialId}">
                <SideMenu
                    @openMenu="openedMenu = materialId"
                    @closeMenu="closeMenu(materialId)"
                    @delMaterial="deleteMaterial(materialId)"
                    @mousedown="setMaterialDraggable(materialId, true)"
                    @mouseup="setMaterialDraggable(materialId, false)"
                    :ref="'menu_' + materialId"
                />
            </div>
            <div style="flex: 1; width: 100%; max-width: 100%">
                <CollabEditor
                    v-if="materialsMap[materialId] && materialsMap[materialId].type === 'ctext'"
                    :material="materialsMap[materialId]"
                    :editable="typesConfig.ctext.isEditable"
                    :ref="'m_' + materialId"
                    @ready="onReady"
                />
                <StaticText
                    v-if="materialsMap[materialId] && materialsMap[materialId].type === 'text'"
                    :material="materialsMap[materialId]"
                    :ref="'m_' + materialId"
                />
                <CollabCode
                    v-if="materialsMap[materialId] && materialsMap[materialId].type === 'code'"
                    :material="materialsMap[materialId]"
                    :ref="'m_' + materialId"
                    @ready="onReady"
                />
                <Images
                    v-if="materialsMap[materialId] && materialsMap[materialId].type === 'images'"
                    :material="materialsMap[materialId]"
                    :ref="'m_' + materialId"
                />
                <Test
                    v-if="materialsMap[materialId] && materialsMap[materialId].type === 'test'"
                    :material="materialsMap[materialId]"
                    :isTestAdmin="isRoomAdmin"
                    :ref="'m_' + materialId"
                />
                <BoardCell
                    v-if="materialsMap[materialId] && materialsMap[materialId].type === 'board' && board_fetched"
                    :roomId="roomId"
                    :cellId="materialId"
                    :isActive="areMaterialsActive"
                    :material="materialsMap[materialId].material"
                    :objects="board.objects_by_cell[materialId]"
                    :ref="'m_' + materialId"
                    @ready="onCellReady(materialId)"
                />
            </div>
        </div>
        <DropdownWithTypes
            v-if="materialsConfig.ableToPostNewMaterials"
            :options="optionsForDropdown"
            style="margin-left: 19px; margin-bottom: 2rem"
        />
        <Drafts
            v-if="materialsConfig.ableToUseDrafts"
            :roomId="roomId"
            :typesConfig="typesConfig"
            :ableToPublish="materialsConfig.showDraftsCircleButton"
            :showCircleButton="materialsConfig.showDraftsCircleButton"
            ref="draftsInstance"
        />
    </div>
</template>

<script>
import * as Y from 'yjs'
import axios from "axios";
import {SERVICE_MATERIALS_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import {fromUint8Array, toUint8Array} from "js-base64";
import {useStore} from "vuex";
import {ref, provide, computed} from "vue";
import CollabEditor from "./CollabText/CollabEditor";
import CollabCode from "@/components/MaterialsV2/CollabCode/CollabCode";
import Images from "@/components/MaterialsV2/Images/Images";
import Test from "@/components/MaterialsV2/Test/Test";
import {BoardsManager} from "@/components/MaterialsV2/BoardsManager/boards_manager";
import SideMenu from "@/components/MaterialsV2/SideMenu";
import StaticText from "@/components/MaterialsV2/StaticText/StaticText";
import Drafts from "@/components/MaterialsV2/Drafts";
import DropdownWithTypes from "@/components/MaterialsV2/DropdownWithTypes";
import publishMaterial from "@/components/MaterialsV2/publish_material";
import Board from "@/components/redesign/Board/Board.vue";
import BoardCell from "@/components/MaterialsV2/BoardCellV3/BoardCell.vue";

export default {
    name: 'Materials',
    components: {
        BoardCell, Board, DropdownWithTypes, Drafts, StaticText,
        SideMenu, Test, Images, CollabCode, CollabEditor,
    },
    props: {
        roomId: {
            type: String,
            required: true,
        },
        isRoomAdmin: {
            type: Boolean,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
        useDrafts: {
            type: Boolean,
            default: false,
        },
        showDraftsButtonAtHead: {
            type: Boolean,
            default: false,
        },
        showDraftsCircleButton: {
            type: Boolean,
            default: false,
        },
        canPostNewMaterials: {
            type: Boolean,
            default: false,
        },
        messageIfNoMaterials: {
            type: String,
            default: 'Пока нет материалов 👀',
        },
        typesConfig: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        let areMaterialsActive = computed(() => props.active);
        provide('areMaterialsActive', areMaterialsActive);
        const materialsConfig = ref({
            isActive: computed(() => props.active),
            ableToUseDrafts: computed(() => props.useDrafts),
            showDraftsButtonAtHead: computed(() => props.showDraftsButtonAtHead),
            showDraftsCircleButton: computed(() => props.showDraftsCircleButton),
            ableToPostNewMaterials: computed(() => props.canPostNewMaterials),
            messageIfNoMaterials: computed(() => props.messageIfNoMaterials),
        });
        const store = useStore();
        let ydoc = new Y.Doc();
        ydoc.on('update', (upd, origin) => {
            if (origin && (origin === 'orderChanger' || origin.hasOwnProperty('cm') || origin.hasOwnProperty('key'))) {
                store.dispatch('ws/sendCRDTUpdate', {
                    materials_id: props.roomId,
                    upd: fromUint8Array(upd),
                });
            }
        });
        provide('ydoc', ydoc);
        let order = ydoc.getArray('order');
        let drafts = ref([]);
        provide('drafts', drafts);
        return {materialsConfig, areMaterialsActive, ydoc, order, drafts};
    },
    computed: {
        optionsForDropdown() {
            const allTypes = [
                { slug: 'ctext', action: this.newCText },
                { slug: 'board', action: this.newBoardCell },
                { slug: 'code', action: this.newCode },
            ];
            const filteredTypes = [];
            allTypes.forEach(type => {
                if (this.typesConfig.hasOwnProperty(type.slug)) {
                    type.name = this.typesConfig[type.slug].name;
                    filteredTypes.push(type);
                }
            });
            filteredTypes.push({ slug: '', name: 'Из черновиков', action: this.showDrafts });
            return filteredTypes;
        },
    },
    data() {
        return {
            loadingMaterials: true,
            openedMenu: null,
            materialIds: [],
            materialsMap: {},
            board: null,
            board_fetched: false,
            cnt_collab: 0,
            cnt_ready: 0,
            dragging: false,
        };
    },
    methods: {
        setMaterialsActive(status) {
            this.areMaterialsActive = status;
        },
        showDrafts() {
            this.$refs.draftsInstance.showDrafts();
        },
        closeMenu(materialId) {
            if (this.openedMenu === materialId) {
                this.openedMenu = null;
            }
        },
        setMaterialDraggable(materialId, value) {
            this.$refs['wrapper_' + materialId][0].setAttribute('draggable', value);
        },
        onStartDragging(evt, materialId) {
            this.$refs['menu_' + materialId][0].closeSettings();
            this.dragging = true;
            evt.dataTransfer.dropEffect = 'move';
            evt.dataTransfer.effectAllowed = 'move';
            evt.dataTransfer.setData('materialId', materialId);
        },
        onDragEnter(evt) {
            evt.target.classList.add('ytm-materials-wrapper-dragover');
        },
        onDragLeave(evt) {
            evt.target.classList.remove('ytm-materials-wrapper-dragover');
        },
        onStopDragging(materialId) {
            this.dragging = false;
            this.setMaterialDraggable(materialId, false);
        },
        onDrop(evt, to) {
            evt.target.classList.remove('ytm-materials-wrapper-dragover');
            const materialId = evt.dataTransfer.getData('materialId');
            const from = this.materialIds.findIndex(id => id === materialId);
            if (from !== to && from !== -1) {
                this.moveItem(from, to);
            }
            this.dragging = false;
            this.setMaterialDraggable(materialId, false);
        },
        moveItem(from, to) {
            this.order.doc.transact(() => {
                const materialId = this.order.get(from);
                this.order.delete(from);
                const newPos = from < to ? to - 1 : to;
                this.order.insert(newPos, [materialId]);
                this.materialIds = this.order.toArray();
            }, 'orderChanger');
        },
        pushMaterialToOrder(materialId) {
            this.order.doc.transact(() => {
                this.order.push([materialId]);
                this.materialIds = this.order.toArray();
            }, 'orderChanger');
        },
        removeMaterialFromOrder(materialId) {
            this.order.doc.transact(() => {
                const idx = this.order.toArray().findIndex(id => id === materialId);
                if (idx !== -1) {
                    this.order.delete(idx);
                }
                this.materialIds = this.order.toArray();
            }, 'orderChanger');
        },
        async fetchCRDTContent() {
            await this.$store.dispatch('auth/autoLogin');
            const resp = await axios.get(SERVICE_MATERIALS_URI + '/updates', {
                params: {room: this.roomId},
                headers: authHeader(),
            });
            const updates = resp.data.updates ? resp.data.updates : resp.data.out;
            if (updates.length > 0) {
                let tmp = new Y.Doc();
                tmp.transact(() => {
                    for (const update of updates) {
                        Y.applyUpdate(tmp, toUint8Array(update.payload));
                    }
                });
                const update = Y.encodeStateAsUpdate(tmp);
                await this.$store.dispatch('auth/autoLogin');
                const payload = {
                    last_upd: updates[updates.length - 1].id,
                    payload: fromUint8Array(update),
                };
                axios.put(SERVICE_MATERIALS_URI + '/updates', payload, {
                    params: {room: this.roomId},
                    headers: authHeader(),
                });
                Y.applyUpdate(this.ydoc, update);
                tmp.destroy();
            }
            const deduplicateOrder = () => {
                this.order.doc.transact(() => {
                    const uniqueIds = new Set();
                    const array = this.order.toArray();
                    for (let i = array.length - 1; i >= 0; i--) {
                        const materialId = array[i];
                        if (uniqueIds.has(materialId)) {
                            this.order.delete(i);
                        } else {
                            uniqueIds.add(materialId);
                        }
                    }
                    this.materialIds = this.order.toArray();
                }, 'orderChanger');
            };
            deduplicateOrder();
            this.order.observe(() => {
                deduplicateOrder();
            });
        },
        async fetchMaterials() {
            await this.$store.dispatch('auth/autoLogin');
            const resp = await axios.get(SERVICE_MATERIALS_URI + '/materials', {
                params: {materials_id: this.roomId},
                headers: authHeader(),
            });
            const materials = resp.data.out.materials;
            const drafts = resp.data.out.drafts;
            for (const material of materials) {
                if (material.type === 'ctext' || material.type === 'code') {
                    this.cnt_collab++;
                }
                this.materialsMap[material.id] = material;
            }
            for (const [i, draft] of drafts.entries()) {
                this.drafts.push({
                    id: i,
                    materialId: draft.id,
                    type: draft.type,
                    content: draft.material,
                });
            }
        },
        mergeIdsWithMaterialsMap() {
            const uniqueIds = new Set();
            const array = this.order.toArray();
            for (const id of array) {
                uniqueIds.add(id);
            }
            for (const [key, _] of Object.entries(this.materialsMap)) {
                if (!uniqueIds.has(key)) {
                    uniqueIds.add(key);
                    this.pushMaterialToOrder(key);
                }
            }
            this.materialIds = this.order.toArray();
        },
        onReady() {
            this.cnt_ready++;
            if (this.cnt_ready === this.cnt_collab && !this.$store.getters['crdt_ws/socketReady']) {
                this.$store.dispatch('crdt_ws/initialize', {
                    ydoc: this.ydoc,
                    room_id: this.roomId,
                });
            }
        },
        onCellReady(cellId) {
            const cell = this.$refs['m_' + cellId][0].$refs.board;
            this.board.registerBoardCell(cell);
        },
        onNewSocketMessage(t, payload) {
            if (t === 2003) {
                this.materialsMap[payload.id] = payload;
                this.pushMaterialToOrder(payload.id);
                if (payload.type === 'board') {
                    this.board.registerBoardCell(payload);
                }
            }
            if (t === 2004) {
                this.$refs['m_' + payload.id][0].updateMaterial(payload);
            }
            if (t === 2005) {
                this.removeMaterialFromOrder(payload.material_id);
                if (this.materialsMap.hasOwnProperty(payload.material_id)) {
                    delete this.materialsMap[payload.material_id];
                }
            }
        },
        newCText() {
            this.publishMaterial({id: -1, type: 'ctext'});
        },
        newCode() {
            this.publishMaterial({id: -1, type: 'code'});
        },
        newBoardCell() {
            this.publishMaterial({id: -1, type: 'board'});
        },
        publishMaterial(draft) {
            publishMaterial(this.roomId, draft);
        },
        deleteMaterial(material_id) {
            this.$store.dispatch('auth/autoLogin').then(() => {
                if (typeof this.$refs['m_' + material_id][0].deleteContent === 'function') {
                    this.$refs['m_' + material_id][0].deleteContent();
                }
                axios.delete(
                    SERVICE_MATERIALS_URI + '/materials',
                    {
                        headers: authHeader(),
                        params: {
                            materials_id: this.roomId,
                            material_id: material_id,
                        },
                    },
                );
            });
        },
    },
    mounted() {
        Promise.all([this.fetchCRDTContent(), this.fetchMaterials()]).then(() => {
            this.mergeIdsWithMaterialsMap();
            this.loadingMaterials = false;
            if (this.cnt_collab === 0 && !this.$store.getters['crdt_ws/socketReady']) {
                this.$store.dispatch('crdt_ws/initialize', {
                    ydoc: this.ydoc,
                    room_id: this.roomId,
                });
            }
            this.$store.dispatch('ws/registerMaterialsRoom', this).then(() => {
                this.$store.dispatch('ws/refocusMaterialsRoom', {rId: this.roomId});
            });
            this.board = new BoardsManager(this.roomId, this.$store, this);
            this.$store.dispatch('ws/registerBoard', this.board).then(() => {
                this.$store.dispatch('ws/refocusBoard', {bId: this.roomId});
            });
        }).catch(err => {
            console.error(err);
        });
    },
    beforeUnmount() {
        this.$store.dispatch('ws/refocusMaterialsRoom', {rId: ''});
        this.$store.dispatch('ws/unregisterMaterialsRoom', this);
        this.$store.dispatch('ws/refocusBoard', {bId: ''});
        if (this.board) {
            this.$store.dispatch('ws/unregisterBoard', this.board);
        }
        this.$store.dispatch('crdt_ws/destroy');
        this.ydoc.destroy();
    },
};
</script>

<style scoped>
.ytm-materials-wrapper {
    display: flex;
}
.ytm-materials-wrapper-dragging > * {
    pointer-events: none;
}
.ytm-materials-wrapper-dragover {
    border-top: 3px solid #3FC4FF;
}
.ytm-material-menu {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}
.ytm-materials-wrapper:hover .ytm-material-menu {
    opacity: 1;
}
.ytm-no-pointer {
    pointer-events: none;
    opacity: 0.4;
}
</style>