<template>
  <div v-if="editor" class="form__item editor">
    <EditorContent :editor="editor"/>
  </div>
</template>

<script>
import {Editor, EditorContent, VueNodeViewRenderer} from "@tiptap/vue-3";
import {Document} from "@tiptap/extension-document";
import {Text} from "@tiptap/extension-text";
import {Heading} from "@tiptap/extension-heading";
import {Paragraph} from "@tiptap/extension-paragraph";
import {Bold} from "@tiptap/extension-bold";
import {Italic} from "@tiptap/extension-italic";
import {Strike} from "@tiptap/extension-strike";
import {Code} from "@tiptap/extension-code";
import {HardBreak} from "@tiptap/extension-hard-break";
import {BulletList} from "@tiptap/extension-bullet-list";
import {OrderedList} from "@tiptap/extension-ordered-list";
import {ListItem} from "@tiptap/extension-list-item";
import {Link} from "@tiptap/extension-link";
import {Placeholder} from "@tiptap/extension-placeholder";
import MenuBar from "@/components/MaterialsV2/CollabText/MenuBar";
import {TaskList} from "@tiptap/extension-task-list";
import {TaskItem} from "@tiptap/extension-task-item";
import YTMFormula from "@/components/MaterialsV2/CollabText/YTMFormula/YTMFormula";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import CodeBlockComponent from "@/components/MaterialsV2/StaticText/CodeBlockComponent";
import {lowlight} from "lowlight/lib/common";

export default {
  name: 'StaticText',
  components: {MenuBar, EditorContent},
  props: {
    material: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.editor = new Editor({
      content: this.material.material.text,
      extensions: [
        Document, Text, Heading, Paragraph,
        Bold, Italic, Strike, Code,
        HardBreak,
        BulletList, OrderedList, ListItem, TaskList, TaskItem,
        CodeBlockLowlight.extend({
          addNodeView() {
            return VueNodeViewRenderer(CodeBlockComponent);
          },
        }).configure({lowlight}),
        Link, YTMFormula,
        Placeholder.configure({
          placeholder: ({ node }) => {
            switch (node.type.name) {
              case 'paragraph':
                return 'Здесь вы можете набирать текст!';
              case 'heading':
                return 'Заголовок';
              default:
                return '';
            }
          },
        }),
      ],
      editable: false,
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style>
.editor {
  color: #0d0d0d;
  background-color: #fff;
}
.editor a {
  color: #007bff;
}
.ytm-tiptap-node-wrapper {}
.ytm-tiptap-control-panel {
  opacity: 0;
}
.ytm-tiptap-node-wrapper:hover .ytm-tiptap-control-panel {
  opacity: 1;
  transition: opacity .25s ease-in;
}
.editor__content {
  flex: 1 1 auto;
  overflow-x: scroll;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.editor__name button {
  background: none;
  border: none;
  font: inherit;
  font-size: 12px;
  font-weight: 600;
  color: #0d0d0d;
  border-radius: 0.4rem;
  padding: 0.25rem 0.5rem;
}
.editor__name button:hover {
  color: #fff;
  background-color: #0d0d0d;
}
/* Basic editor styles */
.ProseMirror > * + * {
  margin-top: 0;
}
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
.ProseMirror p.is-empty::before {
  position: absolute;
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
.ProseMirror h1.is-empty::before, h2.is-empty::before, h3.is-empty::before {
  position: absolute;
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
.ProseMirror ul, .ProseMirror ol {
  padding: 0.2rem 1rem;
}
.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}
.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}
.ProseMirror pre .hljs-comment, .ProseMirror pre .hljs-quote {
  color: #616161;
}
.ProseMirror pre .hljs-variable, .ProseMirror pre .hljs-template-variable, .ProseMirror pre .hljs-attribute, .ProseMirror pre .hljs-tag, .ProseMirror pre .hljs-name, .ProseMirror pre .hljs-regexp, .ProseMirror pre .hljs-link, .ProseMirror pre .hljs-name, .ProseMirror pre .hljs-selector-id, .ProseMirror pre .hljs-selector-class {
  color: #f98181;
}
.ProseMirror pre .hljs-number, .ProseMirror pre .hljs-meta, .ProseMirror pre .hljs-built_in, .ProseMirror pre .hljs-builtin-name, .ProseMirror pre .hljs-literal, .ProseMirror pre .hljs-type, .ProseMirror pre .hljs-params {
  color: #fbbc88;
}
.ProseMirror pre .hljs-string, .ProseMirror pre .hljs-symbol, .ProseMirror pre .hljs-bullet {
  color: #b9f18d;
}
.ProseMirror pre .hljs-title, .ProseMirror pre .hljs-section {
  color: #faf594;
}
.ProseMirror pre .hljs-keyword, .ProseMirror pre .hljs-selector-tag {
  color: #70cff8;
}
.ProseMirror pre .hljs-emphasis {
  font-style: italic;
}
.ProseMirror pre .hljs-strong {
  font-weight: 700;
}
.ProseMirror mark {
  background-color: #faf594;
}
.ProseMirror img {
  max-width: 100%;
  height: auto;
}
.ProseMirror hr {
  margin: 1rem 0;
}
.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}
.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}
.ProseMirror ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
}
.ProseMirror ul[data-type="taskList"] li {
  display: flex;
  align-items: center;
}
.ProseMirror ul[data-type="taskList"] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}
.ProseMirror ul[data-type="taskList"] li > div {
  flex: 1 1 auto;
}
.form__item {
  margin: 0 0 1rem;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  border: 1px solid #e9ecef;
  transition: 0.1s all ease-in-out;
}
.form__item:hover {
  border-color: #68cef8;
}
</style>