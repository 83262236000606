<template>
    <div class="ytm-material-wrapper" style="padding-top: 1.5rem">
        <div
                class="ytm-images-load-field"
                @drop="onFilesDropped(draft, $event)"
                @dragover.prevent
                @drop.prevent
                style="display: table; margin-bottom: 0.5rem"
        >
            <p class="ytm-default-text" style="font-weight: 400; color: #9E9E9E; display: table-cell; vertical-align: middle">
                Перетащите сюда файл или кликните, чтобы открыть проводник
            </p>
            <input
                    class="ytm-file-input"
                    type="file" multiple="multiple"
                    accept="image/png,image/x-png,image/gif,image/jpeg"
                    @change="onFilesSelected(draft, $event)"
            />
        </div>
        <div style="margin-top: 0.5rem">
            <p class="ytm-default-text" v-for="file in draft.files">
                {{file.name}}
            </p>
        </div>
        <Input
                v-model="draft.description"
                placeholder="Здесь можно добавить описание"
                style="margin-top: 1rem"
        />
        <div v-if="ableToPublish" style="display: flex; margin-top: 1rem">
            <div
                    class="ytm-black-button ytm-default-text"
                    style="background: #07A8F0; color: #FFFFFF; font-weight: 600"
                    @click="$emit('publish')"
            >
                Опубликовать
            </div>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import Input from "@/components/redesign/Form/Input";

export default {
    name: 'ImagesDraft',
    components: {Input},
    props: {
        draft: {
            type: Object,
            required: true,
        },
        ableToPublish: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onFilesSelected(draft, event) {
            for (let i = 0; i < event.target.files.length; i++) {
                if (draft.files.length < 10) {
                    draft.files.push(event.target.files[i]);
                }
            }
        },
        onFilesDropped(draft, event) {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                if (draft.files.length < 10) {
                    draft.files.push(event.dataTransfer.files[i]);
                }
            }
        },
    },
};
</script>

<style scoped>
.ytm-material-wrapper {
    margin: 0 0 1rem;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    border: 1px solid #e9ecef;
    transition: 0.1s all ease-in-out;
}

.ytm-images-load-field {
    position: relative;
    border: 1px dashed #9E9E9E;
    border-radius: 0.8rem;
    width: 100%;
    min-height: 5rem;
    background: rgba(238, 238, 238, 0.5);
    text-align: center;
    cursor: pointer;
}

.ytm-file-input {
    opacity: 0.0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height:100%;
    cursor: pointer;
}

.ytm-material-wrapper:hover {
    border-color: #68cef8;
}
</style>